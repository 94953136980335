<template>
  <div style="background-color: #f0c2ed; height: 10%; padding-top: 20px;">
  <div style="color: black;">
    <div class="footerText">
      Rheumatology Associates Brampton 2024
      <div class="terms">
        By using this website, you agree to the <a href="./terms.pdf" class="footerLink">terms and conditions.</a>
      </div>
    </div>
    
  </div>
</div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>

<style scoped>
.terms {
  color: black;
  font-size: 18px;
  background-color: #f0c2ed;
  padding-bottom: 20px;
}
.footerText{
  font-size: 40px;
}
.footerLink {
  color: blue;
}
</style>